import React, {useContext, useEffect, useState } from 'react';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import { listaMeses } from '../listasPlanoSafra';
import { stdValue } from '../../Resources/setNumber';
import { UseColors } from '../../components/ui/colors' 
import '../../css/planoSafra.css'
import ModalScreenMessage from '../../components/ui/ModalScreenMessage';
import { getStdPlanoSafra } from '../../BME/Unidades/Geral/getStdPlanoSafra';
import HzDivider from '../../components/ui/hzDivider';

export default function BodyCompRevs({
    secs = {'MATÉRIA PRIMA':[0,100], 'UPTIME':[0,100], 'MOAGEM':[0,100], 'MIX':[0,100], 
        'EFICIÊNCIAS':[0,100], 'PERDAS': [0,100], 'AÇÚCAR':[0,100], 'ETANOL':[0,100], 'ENERGIA':[0,100], 
        'VAPOR':[0,100], 'BIOMASSA':[0,100], 'DIAS NÃO EFETIVOS':[0,100]}
}) {
  const {allPsData, selectedSafra1, selectedSafra2, selectedRev1,
    selectedRev2, psData1, psData2, psIsPrinting} = useContext(PSContext)
    const {setMenu, setArea, site} = useContext(GlobalStateContext)

    const stdPlanoVars = getStdPlanoSafra(site)

useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
    setMenu('ps')
    setArea('compRevisoes')
  }, []);

  const COLORS = UseColors()

    let nwSites = ['usJbAlcoolquimica']
    let months = nwSites.includes(site) ? ['set', 'out', 'nov', 'dez', 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago'] : ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']

  function getValue(data, cod, mes){
    
    let filtData = data?.filter(i =>  i.codigo === cod)[0]
    // console.log('Data', data, cod, filtData)
    if (!filtData){
        return 0.0
    }
    let value = filtData[mes]
    if (value){
        return stdValue(value)
    }else{
        return 0.0
    }
  }

  function getDifValue(data1, data2, cod, mes){
    let filtData1 = data1.filter(i =>  i.codigo === cod)[0]
    let filtData2 = data2.filter(i =>  i.codigo === cod)[0]
    if (!filtData1 || !filtData2){
        return 0.0
    }
    let dif = filtData2[mes] - filtData1[mes]
    if (dif){
        return stdValue(dif)
    }else{
        return 0.0
    }
  }

  if (allPsData && selectedSafra1 && selectedRev1 && selectedSafra2 && selectedRev2){
    return (
        <>
             <div>
            
                {psData1 && psData2 && 
                <div className='psTableHead'>
                    <div style={{width: '13%', textAlign: 'left'}}>Descrição</div>
                    <div style={{width: '6%', textAlign: 'left'}}>Un.</div>
                    <div style={{width: '9%', textAlign: 'left'}}>Revisão</div>
                    
                    {months.map((item, index) => {
                        return(
                            <div className='psHeadElement' style={{width: '5.33%'}} key={item}>
                                <div>{item}</div>
                            </div>
                        )
                    })}
                    <div style={{width: '8%', textAlign: 'center'}}>Acumulado</div>
                </div>}
                </div>
                
                <div  className='psBody' 
                style={psIsPrinting ? 
                    {maxHeight: '150vh', color: 'black', margin: '0'} :
                    {maxHeight: '80vh', color: 'black'}}>
            {psData1 && psData2 && selectedSafra1 && selectedRev1 && selectedSafra2 && selectedRev2 &&
            <div style={{ margin: '0'}}>
              {Object.keys(psData1)
              .filter(key => Object.keys(secs).includes(key))
              .map(secao => (
                <div key={secao}>
                <div className='psSectionHead'>{secao}</div>
                  
                  <div style={{ marginTop: '0vh', backgroundColor: 'white', borderRadius: '1vh'}}>
                    {stdPlanoVars.filter(i => i.grp === secao && i.show !== false)
                    .map((item, index) => {
                        if (secs[secao][0] <= index  && secs[secao][1] >= index){
                            return (
                                <div className={`psCompLine ${index%2 === 0 && "psLineImp"}`}>
                                <div style={{width: '13%', textAlign: 'left'}}>{item.desc}</div>
                                <div style={{width: '6%', textAlign: 'left'}}>{item.unid}</div>
                                <div  className='psCompSubLine' style={{width: '9%', textAlign: 'left'}}>
                                    <div >
                                        {`${psData2[secao][0].safra}_${psData2[secao][0].revisao}`.slice(0, 15)}
                                    </div>
                                    <HzDivider/>
                                    <div >
                                    {`${psData1[secao][0].safra}_${psData1[secao][0].revisao}`.slice(0, 15)}
                                    </div>
                                    <HzDivider/>
                                    <div >
                                        {'Diferença'}
                                    </div>
                                </div>
            
                                {months.map(mes => {
                                    const month = mes
                                    return (
                                        <div className='psCompSubLine' style={{width: '5.33%', textAlign: 'center', alignContent: 'center'}}>
                                            <div  >
                                                {getValue(psData2[secao], item.cod, month)}
                                            </div>
                                            <HzDivider/>
                                            <div >
                                                {getValue(psData1[secao], item.cod, month)}
                                            </div>
                                            <HzDivider/>
                                            <div >
                                                {getDifValue(psData1[secao], psData2[secao], item.cod, month)}
                                            </div>
                                        </div>
                                      
                                    )
                                })}
                              
                              <div className='psCompSubLine' style={{width: '8%', textAlign: 'center'}}>
                                    <div >
                                        {getValue(psData2[secao], item.cod, 'acu')}
                                    </div>
                                    <HzDivider/>
                                    <div >
                                        {getValue(psData1[secao], item.cod, 'acu')}
                                    </div>
                                    <HzDivider/>
                                    <div >
                                        {getDifValue(psData1[secao], psData2[secao], item.cod, 'acu')}
                                    </div>
                                </div>
                              </div>
                            )
                        }
                    })}
                  </div>
                </div>
              ))}
            </div>}
            <div style={{height: '2vh'}}></div>
            
            </div>
            </>
          )
  }else{
    if (!psData1 || !psData2 || !selectedRev1 || !selectedRev2){
        return (
            <ModalScreenMessage texto={'Selecione a Safra e Revisão 1 e 2.'}/>
        )
    }else{
        return (
            <ModalScreenMessage texto={'Carregando Dados...'}/>
        )
    }
  }
  
}