
import React, { createContext, useState } from 'react';

// Criando o contexto
export const PSContext = createContext();

// Provedor de contexto
export const PSContextProvider = ({ children }) => {
  const [psIsSaved, setPsIsSaved] = useState(true); 
  const [isNew, setIsNew] = useState(false); 
  const [isSave, setIsSave] = useState(false); 
  const [isSaveAs, setIsSaveAs] = useState(false); 
  const [isOpen, setIsOpen] = useState(true); 
  const [isExpPdf, setIsExpPdf] = useState(false); 
  const [isEdit, setIsEdit] = useState(false); 
  const [isChart, setIsChart] = useState(false); 
  const [psData, setPsData] = useState(null);
  const [allPsData, setAllPsData] = useState(null);
  const [psDataReal, setPsDataReal] = useState(null);
  const [editingMonth, setEditingMonth] = useState('Janeiro');
  const [selectedCharts, setSelectedCharts] = useState(0);
  const [listSafras, setListSafras] = useState(null);
  const [listRevs1, setListRevs1] = useState(null);
  const [listRevs2, setListRevs2] = useState(null);
  const [selectedSafra1, setSelectedSafra1] = useState(null);
  const [selectedSafra2, setSelectedSafra2] = useState(null);
  const [selectedRev1, setSelectedRev1] = useState(null);
  const [selectedRev2, setSelectedRev2] = useState(null);
  const [psData1, setPsData1] = useState(null);
  const [psData2, setPsData2] = useState(null);
  const [psIsPrinting, setPsIsPrinting] = useState(false);
  const [showChartPsComp, setShowChartPsComp] = useState(false);
  const [selectedCompCharts, setSelectedCompCharts] = useState(0);
  const [firstCrop, setFirstCrop] = useState('');
  const [selectedValues, setSelectedValues] = useState({})
  const [showChart10year, setShowChart10year] = useState(false);
  return (
    <PSContext.Provider value={{ 
      psIsSaved, setPsIsSaved, isNew, setIsNew, isSave, setIsSave, isSaveAs, setIsSaveAs,
        isOpen, setIsOpen, isExpPdf, setIsExpPdf, isEdit, setIsEdit,
        isChart, setIsChart, psData, setPsData, allPsData, setAllPsData, psDataReal, setPsDataReal,
        editingMonth, setEditingMonth, selectedCharts, setSelectedCharts, listSafras, setListSafras, listRevs1, setListRevs1,
        listRevs2, setListRevs2, selectedSafra1, setSelectedSafra1, selectedSafra2, setSelectedSafra2, selectedRev1, setSelectedRev1,
        selectedRev2, setSelectedRev2, psData1, setPsData1, psData2, setPsData2, psIsPrinting, setPsIsPrinting,
        showChartPsComp, setShowChartPsComp, selectedCompCharts, setSelectedCompCharts, firstCrop, setFirstCrop, selectedValues, setSelectedValues,
        showChart10year, setShowChart10year
        }}>
      {children}
    </PSContext.Provider>
  )
}

export default PSContext;