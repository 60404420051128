const PsChartsDataJBA =  {
        'MATÉRIA PRIMA': [
            ['psAtrPcts', 'psAtrDigestor'],
            ['psFibraCana', 'psBagacoCana'],
            ['', ''],
        ],
        'MOAGEM': [
            ['psUptimeGeral', 'psDiasSafra'],
            ['psTaxaMoagem', 'psMoagemTotal'],
            ['', ''],
        ],
        'EFICIÊNCIAS': [
            ['psArtEntrado', 'psArtRecuperado'],
            ['psEficienciaIndustrial', 'psRTC'],
            ['psConsVaporTc', 'psPtConsumidaTC']
        ],
        'PERDAS': [
            ['psPerdaBagaco', 'psPerdaLavavem'],
            ['psPerdaTorta', 'psPerdaMultijato'],
            ['psPerdaFermentacao', 'psPerdaResiduaria'],
            ['psPerdaIndeterminada', '']
        ],
        'PRODUÇÕES': [
            ['psProdAcucarTotalSC', 'psProdEtanolTotalHid'],
            ['psProdEtanolAnidro', 'psProdEtanolNeutro'],
            ['psProdEtanolDest', 'psEnergiaExportada'],
        ],
        'ESTOQUES': [
            ['psEstoqueInicialBag', 'psEstoqueFinalBag'],
            ['', ''],
            ['', ''],
        ],
        'PAGE1': [
            ['psAtrDigestor', 'psFibraCana'],
            ['psUptimeGeral', 'psMoagemTotal'],
            ['psTaxaMoagem', 'psRTC'],
        ],
        'PAGE2': [
            ['psProdAcucarTotalSC', 'psProdEtanolTotalHid'],
            ['psProdEtanolAnidro', 'psProdEtanolNeutro'],
            ['psProdEtanolDest', 'psEnergiaExportada']
            // ['psEnergiaConsumidaInd', 'psEnergiaConsumidaAgr'],
        ]
    }

export default PsChartsDataJBA;

