const listaSafras = ['Estudo', '23/24', '24/25', '25/26', '26/27', '27/28', '28/29', '29/30', '30/31', '31/32', '32/33', '33/34']
const listaRevs = ['0_Inicial', '1_Jan', '2_Fev', '3_Mar', '4_Abr', '5_Mai', '6_Jun', '7_Jul', '8_Ago', '9_Set', '10_Out', '11_Nov', '12_Dez', '13_Realizado']
const listaMeses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']



// const stdPlanoVars = [
//     //MATERIA PRIMA
//     {grp: 'MATÉRIA PRIMA', cod: 'psAtrPcts', desc: 'ATR PCTS', unid: 'kg/tc'},
//     {grp: 'MATÉRIA PRIMA', cod: 'psAtrDigestor', desc: 'ART Digestor', unid: '%'},
//     {grp: 'MATÉRIA PRIMA', cod: 'psFibraCana', desc: 'Fibra Cana', unid: '%'},
//     {grp: 'MATÉRIA PRIMA', cod: 'psFibraBagaco', desc: 'Fibra Bagaço', unid: '%'},
//     {grp: 'MATÉRIA PRIMA', cod: 'psBagacoCana', desc: 'Bagaço%Cana', unid: '%'},
//     //UPTIME
//     {grp: 'UPTIME', cod: 'psDowntimeClima', desc: 'Downtime Climático', unid: '%', ipt: true, show: false},
//     {grp: 'UPTIME', cod: 'psDowntimeAgr', desc: 'Downtime Agrícola', unid: '%', ipt: true, show: false},
//     {grp: 'UPTIME', cod: 'psDowntimeInd', desc: 'Downtime Indústria', unid: '%', ipt: true, show: false},
//     {grp: 'UPTIME', cod: 'psDowntimeOutros', desc: 'Downtime Outros', unid: '%', ipt: true, show: false},
//     {grp: 'UPTIME', cod: 'psUptimeGeral', desc: 'Uptime Geral', unid: '%', ipt: true},
//     {grp: 'UPTIME', cod: 'psDiasSafra', desc: 'Dias de Safra', unid: 'dias', ipt: true},
//     //MOAGEM
//     {grp: 'MOAGEM', cod: 'psEficExtracao', desc: 'Eficiência Extração', unid: '%', show: false},
//     {grp: 'MOAGEM', cod: 'psTaxaMoagem', desc: 'Taxa Moagem', unid: 'ton/h'},
//     {grp: 'MOAGEM', cod: 'psTaxaArt', desc: 'Taxa Proc. ART', unid: 'ton/h'},
//     {grp: 'MOAGEM', cod: 'psTaxaAtr', desc: 'Taxa Proc. ATR', unid: 'ton/h'},
//     {grp: 'MOAGEM', cod: 'psTaxaFibra', desc: 'Taxa Proc. Fibra', unid: 'ton/h'},
//     {grp: 'MOAGEM', cod: 'psMoagemTotal', desc: 'Moagem Total', unid: 'ton'},
//     {grp: 'MOAGEM', cod: 'psMoagemDiaria', desc: 'Moagem Média Diária', unid: 'ton/dia'},
//     {grp: 'MOAGEM', cod: 'psMoagemDiariaEf', desc: 'Moagem Efetiva Diária', unid: 'ton/dia'},
//     {grp: 'MOAGEM', cod: 'psTaxaProcFibra', desc: 'Taxa Processamento Fibra', unid: 'ton/h', show: false},
//     // MIX
//     {grp: 'MIX', cod: 'psMixAcucar', desc: 'Mix Açúcar', unid: '%'},
//     {grp: 'MIX', cod: 'psMixEtanol', desc: 'Mix Etanol', unid: '%'},
//     //EFICIENCIAS
//     {grp: 'EFICIÊNCIAS', cod: 'psArtEntrado', desc: 'Art Entrado', unid: 'ton'},
//     {grp: 'EFICIÊNCIAS', cod: 'psArtRecuperado', desc: 'Art Recuperado', unid: 'ton'},
//     {grp: 'EFICIÊNCIAS', cod: 'psPerdaLavavem', desc: 'Perda Lavagem Cana', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psPerdaBagaco', desc: 'Perda Bagaço', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psPerdaTorta', desc: 'Perda Torta', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psPerdaMultijato', desc: 'Perda Multijatos', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psPerdaFermentacao', desc: 'Perda Ferm./Dest.', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psPerdaResiduaria', desc: 'Perda Residuária', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psPerdaIndeterminada', desc: 'Perda Indeterminadas', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psEficienciaIndustrial', desc: 'Eficiência Industrial', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psRTC', desc: 'RTC', unid: '%', show: false},
//     {grp: 'EFICIÊNCIAS', cod: 'psRecSJM', desc: 'Rec. Fábrica (SJM)', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psProdUnieqs', desc: 'Unidades Equivalentes', unid: 'unieqs', show: false},
//     {grp: 'EFICIÊNCIAS', cod: 'psProdUnieqsTc', desc: 'Unieqs / Ton Cana', unid: 'unieqs/tc', show: false},
//     {grp: 'EFICIÊNCIAS', cod: 'psProdUnicops', desc: 'Unicops', unid: 'unicop', show: false},
//     {grp: 'EFICIÊNCIAS', cod: 'psRitStab', desc: 'RIT STAB', unid: 'kg/tc'},
//     {grp: 'EFICIÊNCIAS', cod: 'psRitArt', desc: 'RIT ART', unid: '%'},
//     {grp: 'EFICIÊNCIAS', cod: 'psRelProdAcAtrEnt', desc: 'Açúcar Prod./Atr Proc.', unid: 'kgAc/kgAtr'},
//     //AÇÚCAR
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarTotal', desc: 'Produção Total Açúcar', unid: 'ton'},
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarTotalSC', desc: 'Produção Total Açúcar', unid: 'sc'},
//     {grp: 'AÇÚCAR', cod: 'psMixProdAcucarVHP', desc: 'Mix Açúcar VHP', unid: '%', ipt: true},
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarVHP', desc: 'Produção Açúcar VHP', unid: 'ton'},
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarVHPSC', desc: 'Produção Açúcar VHP', unid: 'sc'},
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarCristal', desc: 'Produção Açúcar Cristal', unid: 'ton'},
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarCristalSC', desc: 'Produção Açúcar Cristal', unid: 'sc'},
    
//     {grp: 'AÇÚCAR', cod: 'psMixProdAcucarC1C', desc: 'Mix Produção C1C', unid: '%', ipt: true, show: false},
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarC1C', desc: 'Produção C1C', unid: 'ton', show: false},
//     {grp: 'AÇÚCAR', cod: 'psMixProdAcucarC1', desc: 'Mix Produção C1', unid: '%', ipt: true, show: false},
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarC1', desc: 'Produção C1', unid: 'ton', show: false},
//     {grp: 'AÇÚCAR', cod: 'psMixProdAcucarC2', desc: 'Mix Produção C2', unid: '%', ipt: true, show: false},
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarC2', desc: 'Produção C2', unid: 'ton', show: false},
//     {grp: 'AÇÚCAR', cod: 'psMixProdAcucarC3', desc: 'Mix Produção C3', unid: '%', show: false},
//     {grp: 'AÇÚCAR', cod: 'psProdAcucarC3', desc: 'Produção C3', unid: 'ton', show: false},
//     {grp: 'AÇÚCAR', cod: 'psTaxaEfProdAcucar', desc: 'Taxa Efetiva Prod. Açúcar', unid: 'ton/dia'},
//     {grp: 'AÇÚCAR', cod: 'psTaxaEfProdAcucarScs', desc: 'Taxa Efetiva Prod. Açúcar', unid: 'sc/dia'},
//     //ETANOL
//     {grp: 'ETANOL', cod: 'psProdEfEtanolHid', desc: 'Prod. Ef.Etanol Hidratado', unid: 'm³/dia'},
//     {grp: 'ETANOL', cod: 'psProdEfEtanolAnidro', desc: 'Prod. Ef. Etanol Anidro', unid: 'm³/dia'},
//     {grp: 'ETANOL', cod: 'psProdEfEtanolNeutro', desc: 'Prod. Ef. Etanol Neutro', unid: 'm³/dia'},
//     {grp: 'ETANOL', cod: 'psProdEfEtanol100', desc: 'Prod. Ef. Etanol 100%', unid: 'm³/dia'},
//     {grp: 'ETANOL', cod: 'psProdEfEtanolHidpTq', desc: 'Etanol Hid. p/ Tanque', unid: 'm³/dia'},
//     {grp: 'ETANOL', cod: 'psRepEfEtanolHid', desc: 'Rep. Ef. Etanol Hidratado', unid: 'm³/dia', show: false},
//     {grp: 'ETANOL', cod: 'psProdTotalEtanol100', desc: 'Prod. Etanol 100%', unid: 'm³'},
//     {grp: 'ETANOL', cod: 'psProdEtanolTotalHid', desc: 'Prod. Etanol Hidratado', unid: 'm³'},
//     {grp: 'ETANOL', cod: 'psProdEtanolAnidro', desc: 'Prod. Etanol Anidro', unid: 'm³'},
//     {grp: 'ETANOL', cod: 'psProdEtanolNeutro', desc: 'Prod. Etanol Neutro', unid: 'm³'},
//     {grp: 'ETANOL', cod: 'psRepEtanolHid', desc: 'Rep. Etanol Hidratado', unid: 'm³', show: false},
//     {grp: 'ETANOL', cod: 'psProdEtanolTotal', desc: 'Prod. Total Etanol', unid: 'm³'},
//     {grp: 'ETANOL', cod: 'psUptimeAnidro', desc: 'Uptime Anidro', unid: '%', ipt: true, show: false},
//     {grp: 'ETANOL', cod: 'psRGD', desc: 'RGD', unid: '%'},
//     //LEVEDURA
//     {grp: 'LEVEDURA', cod: 'psProdLevedura', desc: 'Prod. Levedura Seca', unid: 'ton', show: false},
//     {grp: 'LEVEDURA', cod: 'psEfProdLevedura', desc: 'Prod. Ef. Levedura Seca', unid: 'ton/dia', show: false},
//     {grp: 'LEVEDURA', cod: 'pslevSecaPorLitrosEt', desc: 'Lev. Seca / Et.H', unid: 'kg/m³', show: false},
//     //COGEN
//     {grp: 'ENERGIA', cod: 'psPtExportada', desc: 'Potência Exportada', unid: 'MW'},
//     {grp: 'ENERGIA', cod: 'psPtConsumida', desc: 'Potência Consumida', unid: 'MW'},
//     {grp: 'ENERGIA', cod: 'psEnergiaExportada', desc: 'Energia Exportada', unid: 'MWh'},
//     {grp: 'ENERGIA', cod: 'psEnergiaCosumida', desc: 'Energia Consumida', unid: 'MWh'},
//     {grp: 'ENERGIA', cod: 'psPtExportadaTC', desc: 'Exp. Energia por TC', unid: 'kWh/tc'},
//     {grp: 'ENERGIA', cod: 'psPtConsumidaTC', desc: 'Cons. Energia por TC', unid: 'kWh/tc'},
//     //VAPOR
//     {grp: 'VAPOR', cod: 'psUpTimeVapor', desc: 'Uptime Vapor', unid: '%', ipt: true},
//     {grp: 'VAPOR', cod: 'psProdEfVapor', desc: 'Prod. Ef. Vapor', unid: '%'},
//     {grp: 'VAPOR', cod: 'psProdVapor', desc: 'Prod. Total Vapor', unid: '%'},
//     {grp: 'VAPOR', cod: 'psConsVaporTc', desc: 'Cons. Vapor/TC Efetivo', unid: 'kgv/tc'},
//     {grp: 'VAPOR', cod: 'psConsVaporTcTotal', desc: 'Cons. Vapor/TC Total', unid: 'kgv/tc'},
//     //BIOMASSA
//     {grp: 'BIOMASSA', cod: 'psProdBagacao', desc: 'Produção Total Bagaço', unid: 'ton'},
//     {grp: 'BIOMASSA', cod: 'psConsBagacao', desc: 'Consumo Total Bagaço', unid: 'ton'},
//     {grp: 'BIOMASSA', cod: 'psProdEfBagacao', desc: 'Prod. Efetiva Bagaço', unid: 'ton/h'},
//     {grp: 'BIOMASSA', cod: 'psConsEfBagacao', desc: 'Cons. Efetivo Bagaço', unid: 'ton/h'},
//     {grp: 'BIOMASSA', cod: 'psSobraBagaco', desc: 'Sobra Bagaço', unid: 'ton'},
//     {grp: 'BIOMASSA', cod: 'psVendaBagaco', desc: 'Venda Bagaço', unid: 'ton', ipt: true},
//     {grp: 'BIOMASSA', cod: 'psAjusteEstoqueBag', desc: 'Ajuste Estoque Bagaço', unid: 'ton', ipt: true},
//     {grp: 'BIOMASSA', cod: 'psEstoqueInicialBag', desc: 'Estoque Inicial Bagaço', unid: 'ton'},
//     {grp: 'BIOMASSA', cod: 'psEstoqueFinalBag', desc: 'Estoque Final Bagaço', unid: 'ton'},
//      //DIAS NAO EFETIVOS
//      {grp: 'DIAS NÃO EFETIVOS', cod: 'dnePtExportada', desc: 'Potência Exportada', unid: 'MW', ipt: true},
//      {grp: 'DIAS NÃO EFETIVOS', cod: 'dneEnergiaExportada', desc: 'Energia Exportada', unid: 'MWh'},
//      {grp: 'DIAS NÃO EFETIVOS', cod: 'dneProdVapor', desc: 'Produção Efetiva Vapor', unid: 'ton/h', ipt: true},
//      {grp: 'DIAS NÃO EFETIVOS', cod: 'dneProdTotalVapor', desc: 'Produção Total Vapor', unid: 'ton'},
//      {grp: 'DIAS NÃO EFETIVOS', cod: 'dneRendCaldeira', desc: 'Rendimento Caldeira', unid: 'kgv/kgb', ipt: true},
//      {grp: 'DIAS NÃO EFETIVOS', cod: 'dneConsBagaco', desc: 'Consumo Efetivo Bagaço', unid: 'ton/h'},
//      {grp: 'DIAS NÃO EFETIVOS', cod: 'dneConsTotalBagaco', desc: 'Consumo Total Bagaço', unid: 'ton'},
//     //OUTROS
//     {grp: 'OUTROS', cod: 'psProdEfVinhaca', desc: 'psProdEfVinhaca', unid: 'm³/dia', show: false},
//     {grp: 'OUTROS', cod: 'psProdVinhacapEtanol', desc: 'Prod. Vinhaça/Etanol Hid.', unid: 'l Vin/ l Et', show: false},
//     {grp: 'OUTROS', cod: 'psProdEfTorta', desc: 'psProdEfTorta', unid: 'ton/dia', show: false},
//     {grp: 'OUTROS', cod: 'psProdTortaTc', desc: 'Prod. Torta/Cana Moída', unid: 'kg/tc', show: false},
    
//     //SETTINGS
//     {grp: 'settings', cod: 'psAlerts', desc: '', unid: '', show: false},
   

// ]

module.exports = {
    listaSafras,
    listaRevs,
    listaMeses
}

 